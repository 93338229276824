import { gql, useQuery } from 'modules/GraphQL';
import { useAuthModal } from 'modules/auth';
import type { AppProps } from 'next/app';
import React from 'react';

export const SubscriptionContext = React.createContext({
  subscription: null as { type: 'monthly' | 'yearly'; endAt: string } | null,
  loading: false as boolean,
  refresh: null as any,
});

const GET_SUBSCRIPTION = gql`
  query MySubscription {
    mySubscription {
      item {
        type
        endAt
      }
    }
  }
`;

export default function SubscriptionProvider({ children }: AppProps['pageProps'] & any) {
  const { session } = useAuthModal();
  const { loading, data, refetch } = useQuery(GET_SUBSCRIPTION, { skip: !session });

  const subscription = data?.mySubscription?.item;

  return <SubscriptionContext.Provider value={{ subscription, loading, refresh: refetch }}>{children}</SubscriptionContext.Provider>;
}
